import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AlertNotifications from "../components/AlertNotifications";
import { getDevices } from "../controller/DeviceController";
import { getGarcons } from "../controller/OperatorsController";
import { getProducts } from "../controller/ProductController";
import * as Types from "../redux/ActionsTypes";
import { TheContent, TheSidebar, TheFooter, TheHeader } from "./index";
import { useHistory } from "react-router";


const TheLayout = ({ redirect }) => {
  const token = useSelector((state) => state.token);

  const dispatch = useDispatch();
  const history = useHistory()

  const handleAllRequests = async () => {
    let garcons = await getGarcons({ token });
    let devices = await getDevices({ token });

    dispatch({ type: Types.SET_OPERATORS, operators: garcons.data })
    dispatch({ type: Types.SET_DEVICES, devices: devices.data })

  };

  useEffect(() => {
    console.log(history, redirect)
    if (redirect) {
      history.push(redirect);
    }
    handleAllRequests();

    return () => { };
  }, []);



  return (
    <div className="c-app c-default-layout">
      <TheSidebar />
      <div className="c-wrapper">
        <TheHeader />
        <div className="c-body">
          <TheContent />
          <AlertNotifications />
        </div>
        <TheFooter />
      </div>
    </div>
  );
};

export default TheLayout;
