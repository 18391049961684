import CIcon from '@coreui/icons-react';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { bounceInUp } from 'react-animations';
import Radium, { StyleRoot } from 'radium';

import * as Types from '../../redux/ActionsTypes';

function AlertNotifications() {
  const dispatch = useDispatch();

  const alertNotifications = useSelector((state) => state.alertNotifications);

  let backgroundColorBox = '';
  let cIconName = '';

  try {
    backgroundColorBox = alertNotifications.color.value;
    cIconName = alertNotifications.icon;
  } catch (error) {
    backgroundColorBox = '#';
    cIconName = 'cil-info';
  }

  const styles = {
    bounceInUp: {
      animation: 'x 1s',
      animationName: Radium.keyframes(bounceInUp, 'bounceInUp'),
    },

    boxComponent: {
      position: 'absolute',
      left: '50%',
      bottom: '10px',
      transform: 'translateX(-50%)',
    },
    message: {
      display: 'table',
      position: 'relative',
      margin: '40px auto 0',
      width: '500px',
      backgroundColor: backgroundColorBox,
      color: '#fff',
      transition: 'all 0.2s ease',
      fontSize: '16px',
    },
    messageBody: {
      display: 'table-cell',
      verticalAlign: 'middle',
      padding: '10px',
    },
    messageIcon: {
      display: 'table-cell',
      verticalAlign: 'middle',
      width: '60px',
      padding: '30px',
      textAlign: 'center',
      backgroundColor: ' rgba(0, 0, 0, 0.25)',
    },
    messageButton: {
      position: 'relative',
      margin: '15px 5px -10px',
      backgroundColor: '--color',
      boxShadow: '0 3px rgb(0 0 0 / 40%)',
      border: 'none',
      padding: '10px 15px',
      fontSize: '16px',
      color: '#fff',
      outline: 'none',
      cursor: 'pointer',
    },
  };

  useEffect(() => {
    try {
      if (alertNotifications.time) {
        setTimeout(() => {
          dispatch({ type: Types.CLEAR_ALERT_NOTIFICATIONS });
        }, alertNotifications.time);
      } else {
        setTimeout(() => {
          dispatch({ type: Types.CLEAR_ALERT_NOTIFICATIONS });
        }, 3000);
      }
    } catch (error) {
      if (alertNotifications)
        dispatch({ type: Types.CLEAR_ALERT_NOTIFICATIONS });
    }

    return () => {};
  }, [alertNotifications]);

  return (
    <StyleRoot>
      <div style={{ height: '50px' }}>
        {alertNotifications && (
          <div style={styles.boxComponent}>
            <div style={styles.bounceInUp}>
              <div style={styles.message}>
                <div style={styles.messageIcon}>
                  <CIcon name={cIconName} height={40}></CIcon>
                </div>
                <div style={styles.messageBody}>
                  <p>{alertNotifications.message}</p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </StyleRoot>
  );
}

export default AlertNotifications;
