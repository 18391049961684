import Cookies from "js-cookie";

export function addProductCarrinho(data) {
  let produtosCarrinho = Cookies.getJSON("carrinhoProdutos");
  if (!produtosCarrinho || !produtosCarrinho instanceof Array) {
    produtosCarrinho = [];
  }
  produtosCarrinho.push(data.product);
  Cookies.set("carrinhoProdutos", JSON.stringify(produtosCarrinho));
}

export function removeProductCarrinho(data) {
  let produtosCarrinho = Cookies.getJSON("carrinhoProdutos");
  if (!produtosCarrinho || !produtosCarrinho instanceof Array) {
    produtosCarrinho = [];
  }
  let itemRemoved = false;
  let newProdutosCarrinhos = produtosCarrinho.filter((p) => {
    if (p.id !== data.product.id) {
      return p;
    } else {
      if (!itemRemoved) {
        itemRemoved = true;
      } else {
        return p;
      }
    }
  });
  Cookies.set("carrinhoProdutos", JSON.stringify(newProdutosCarrinhos));
}

export function clearCookiesCarrinhosProdutos(data) {
    Cookies.set("carrinhoProdutos", JSON.stringify([]));
}

export function setCookiesPedido(pedidoInfo){
  Cookies.set("pedidos", JSON.stringify(pedidoInfo));
} 


export function setCookiesMonotokenPedido(monotoken){
  Cookies.set("monotoken", JSON.stringify(monotoken));
} 