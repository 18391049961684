import {
  CCard,
  CCardBody,
  CCardTitle,
  CInput,
  CInputRadio,
  CLabel,
} from "@coreui/react";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FormCliente from "../../../components/Subdomain/FormCliente";
import FormEndereco from "../../../components/Subdomain/FormEndereco";
import FormMetodoPagamento from "../../../components/Subdomain/FormMetodoPagamento";
import { updateStateConfirmPedidoController } from "../../../controller/SubdomainFormConfirmacaoPedidoController";
import ListProducts from "../ListProducts";

function TelaConfirmarPedido({
  carrinhoProdutos,
  actionRemoveProductCar,
  restaurante,
}) {
  let enderecosAtendidos = restaurante.enderecos_atendidos;
  let metodoPagamentos = restaurante.metodo_pagamentos_aceitos;

  const stateConfirmPedidoForm = useSelector(
    (state) => state.subdomainConfirmacaoPedidoForm
  );

  

  return (
    <div>
      <CCard>
        <CCardBody>
          <CCardTitle>Produtos Selecionados</CCardTitle>
          <ListProducts
            produtos={carrinhoProdutos}
            actionRemoveProductCar={(e) => actionRemoveProductCar(e)}
            removeTotalValue
          />
        </CCardBody>
      </CCard>

      <CCard>
        <CCardBody>
          <CCardTitle>Informações do Cliente</CCardTitle>
          <FormCliente state={stateConfirmPedidoForm.cliente} />
        </CCardBody>
      </CCard>

      <CCard>
        <CCardBody>
          <CCardTitle>Formulario de Endereço</CCardTitle>
          <FormEndereco
            enderecosAtendidos={enderecosAtendidos}
            state={stateConfirmPedidoForm.endereco}
          />
        </CCardBody>
      </CCard>

      <CCard>
        <CCardBody>
          <CCardTitle>Métodos de Pagamento</CCardTitle>
          <FormMetodoPagamento
            metodoPagamentos={metodoPagamentos}
            state={stateConfirmPedidoForm.metodoPagamento}
          />
        </CCardBody>
      </CCard>
    </div>
  );
}

export default TelaConfirmarPedido;
