import React from 'react'
import CIcon from '@coreui/icons-react'

const _nav =  [
  {
    _tag: 'CSidebarNavItem',
    name: 'Início',
    to: '/dashboard',
    icon: <CIcon name="cil-speedometer" customClasses="c-sidebar-nav-icon"/>
  },
  {
    _tag: 'CSidebarNavTitle',
    _children: ['Opções']
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Vendas',
    to: '/vendas',
    icon: 'cil-credit-card',
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Pedidos',
    to: '/pedidos',
    icon: 'cil-money',
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Produtos',
    to: '/produtos',
    icon: 'cil-fastfood',
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Mesas do Restaurante',
    to: '/mesas',
    icon: 'cil-restaurant',
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Responsável pelo Atendimento',
    to: '/responsavel-atendimento',
    icon: 'cil-people',
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Dispositivos Conectados',
    to: '/dispositivos-conectados',
    icon: 'cil-screen-smartphone',
  }
]

export default _nav
