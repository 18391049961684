import api from "../services/api";

export async function getRequestSales({ token }, mode = null) {
  try {
    let url = `/pedidos`;
    if (mode) {
      url = `${url}?mode=${mode}`;
    }
    const response = await api.get(url, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });

    return response.data;
  } catch (error) {
    return { error: error };
  }
}

export async function getStatusPedidos({ token }) {
  try {
    let url = `/statusPedido`;

    const response = await api.get(url, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });

    return response.data;
  } catch (error) {
    return { error: error };
  }
}


export async function changeStatusPedidoOfPedido({ token, pedidoId, statusPedidoId} ) {
    try {
      let url = `/updatePedidoStatusPedido/${pedidoId}/${statusPedidoId}`;
  
      const response = await api.post(url, {}, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
  
      return response.data;
    } catch (error) {
      return { error: error };
    }
  }

  export async function getPedidoExterno({ monotoken, pedidoId} ) {
    try {
      let url = `/pedidos/${pedidoId}?monotoken=${monotoken}`;
  
      const response = await api.get(url);
  
      return response.data;
    } catch (error) {
      return { error: error };
    }
  }


  