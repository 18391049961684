import {
  CAlert,
  CButton,
  CCard,
  CCardBody,
  CCol,
  CForm,
  CFormGroup,
  CInput,
  CInputRadio,
  CLabel,
} from "@coreui/react";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateStateConfirmPedidoController } from "../../controller/SubdomainFormConfirmacaoPedidoController";
import { setDangerAlertNotifications } from "../../controller/AlertNotificationsController";

function FormMetodoPagamento({ metodoPagamentos = [], state }) {
  let inistateMetodoPagamento = ""
  let initstateNamedMetodoPagamento = ""
  let iniConfirm = false
  if (state){
    inistateMetodoPagamento = state.id
    initstateNamedMetodoPagamento = state.titulo
    iniConfirm = true
  }

  const [confirm, setConfirm] = useState(iniConfirm);
  const [metodoPagamento, setMetodoPagamento] = useState(inistateMetodoPagamento);
  const [namedMetodoPagamento, setNamedMetodoPagamento] = useState(initstateNamedMetodoPagamento);

  const dispatch = useDispatch();
  const stateConfirmPedido = useSelector(
    (state) => state.subdomainConfirmacaoPedidoForm
  );

  const confirmPagamento = (e) => {
    e.preventDefault();


    let metodo = metodoPagamentos.find(el => el.id === metodoPagamento)
    if (!metodo){
      setDangerAlertNotifications(dispatch, {"message": "Por favor, confira as informações. Método de Pagamento é obrigatório."});
      return;
    }


    setNamedMetodoPagamento(metodo.titulo)  

    let newStateConfirm = {
      ...stateConfirmPedido,
      metodoPagamento: metodo,
    };
    setConfirm(true)

    updateStateConfirmPedidoController(dispatch, newStateConfirm);
  };

  const backPagamento = (e) => {
    setConfirm(false);

    let newStateConfirm = {
      ...stateConfirmPedido,
      metodoPagamento: null,
    };

    updateStateConfirmPedidoController(dispatch, newStateConfirm);
  };
  return (
    <div>
      <div className={`editableScreen ${!confirm ? "" : "d-none"}`}>
        <CForm onSubmit={confirmPagamento}>
          <CFormGroup>
            <p>
              <b>Método de Pagamento</b>
            </p>
            {metodoPagamentos.map(({ id, titulo }) => {
              return (
                <div key={id} className="ml-4">
                  <CInputRadio checked={id === metodoPagamento} onChange={() => setMetodoPagamento(id)} required name={"titulo"} id={id} />
                  <CLabel required htmlFor={id}>{`${titulo}`}</CLabel>
                </div>
              );
            })}
          </CFormGroup>
          <CButton type="submit" color="primary">
            Confirmar Informações de Pagamento
          </CButton>
        </CForm>
      </div>
      <div className={`confirmScreen ${confirm ? "" : "d-none"}`}>
        <CAlert color="success">
          <div>
            <ul>
              <li>
                <b>Método de Pagamento</b> - {namedMetodoPagamento}
              </li>
            </ul>
          </div>
          <hr />
          <span>Método de Pagamento Definido Com Sucesso</span>
        </CAlert>

        <CButton color="secondary" onClick={backPagamento}>
          Voltar a Editar
        </CButton>
      </div>
    </div>
  );
}

export default FormMetodoPagamento;
