import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export const RestaurantName = () => {
  const { nome } = useSelector((state) => state.restauranteInfo);
  return (
    <div className="restaurant-name-container c-header-brand mx-auto active">
      <span className="restaurant-name-text">
        {nome}
      </span>
    </div>
  );
}
