import * as Types from "../redux/ActionsTypes";
import api from "../services/api";

export function updateStateConfirmPedidoController(dispatch, update) {
  if (!dispatch) {
    throw new Error('Parâmetro "dispatch" e "selector" é obrigatório');
  }

  dispatch({
    type: Types.SET_SUBDOMAIN_PEDIDO_FORM_CONFIRMACAO_PEDIDO,
    subdomainConfirmacaoPedidoForm: update,
  });
}

export function clearStateConfirmPedidoController(dispatch) {
  if (!dispatch) {
    throw new Error('Parâmetro "dispatch" e "selector" é obrigatório');
  }

  dispatch({
    type: Types.CLEAR_SUBDOMAIN_PEDIDO_FORM_CONFIRMACAO_PEDIDO,
  });
}

export function confirmacaoPedidoOk(state) {
  if (state.cliente && state.endereco && state.metodoPagamento) {
    return true;
  } else {
    return false;
  }
}

export async function createCliente(cliente, endereco){
  let rua = endereco.endereco;

  let body = {...cliente, ...endereco, ...endereco.bairro, rua};

  try {
    const response = await api.post(`/externo/clientes`, body);
    return response.data;
  } catch (error) {
    return { error: error };
  }
}

export async function createPedido(subdomain, idCliente,idMetodoPagamento, idsProdutos, enderecoid){

  let body = {
    subdomain, 
    produtos: idsProdutos,
    metodo_pagamento_id: idMetodoPagamento,
    cliente_id: idCliente,
    enderecoid
  }

  try {
    const response = await api.post(`/externo/pedidos`, body);
    return response.data;
  } catch (error) {
    return { error: error };
  }
}