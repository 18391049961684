import { CBadge } from "@coreui/react";

import React from "react";
import BadgeRealTimeComponent from "../RealTime";

import Radium, { StyleRoot } from "radium";
import { flash } from "react-animations";
function StatusPedidoBadge({
  statusPedido,
  style = { fontSize: 17 },
  realTime = false,
}) {
  let titulo = statusPedido.titulo;
  let descricao = statusPedido.descricao;

  const styles = {
    flash: {
      animation: "1s",
      animationName: Radium.keyframes(flash, "flash"),
    },
  };

  return (
    <StyleRoot>
      <div style={styles.flash}>
        <CBadge
          title={descricao}
          key={statusPedido.id}
          style={style}
          color="success"
          className={'m-2'}
        >
          {titulo}
        </CBadge>
      </div>

      {realTime && <BadgeRealTimeComponent />}
    </StyleRoot>
  );
}

export default StatusPedidoBadge;
