class DataHandler {
  static groupBy = function groupBy(xs, key) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  static handleDataToViewProductCarrinho =
    function handleDataToViewProductCarrinho(produtos) {
      let produtosById = DataHandler.groupBy(produtos, "id");
      return produtosById;
    };
}

export default DataHandler;
