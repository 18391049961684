import { CBadge, CButton, CHeader, CHeaderNav } from "@coreui/react";
import React from "react";
import { BiCart } from "react-icons/bi";
import StepsSubdomain from "../controller/StepController";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { clearStateConfirmPedidoController, confirmacaoPedidoOk } from "../controller/SubdomainFormConfirmacaoPedidoController";
import { useDispatch, useSelector } from "react-redux";

function TheFooterNextStepExterno({
  currentStep,
  actionNextStep,
  actionPreviousStep,
  hasProdutoInCarrinho,
}) {
  const dispatch = useDispatch();
  const stateConfirmPedidoForm = useSelector(state => state.subdomainConfirmacaoPedidoForm)


  let isValidButtonBack = [
    StepsSubdomain.TELA_CONFIRMACAO_PEDIDO
  ].includes(currentStep);

  let isValidButtonNext =
    [StepsSubdomain.TELA_LISTAGEM_PEDIDO].includes(currentStep) &&
    hasProdutoInCarrinho;

  if (
    currentStep === StepsSubdomain.TELA_CONFIRMACAO_PEDIDO &&
    confirmacaoPedidoOk(stateConfirmPedidoForm)
  ) {
    isValidButtonNext = true;
  }


  return (
    <div>
      <div
        style={{
          background: "#fff",
          borderBottom: " 1px solid #d8dbe0",
          borderTop: " 1px solid #d8dbe0",
          position: "fixed",
          bottom: 0,
          width: "100%",
        }}
      >
        <div
          className="d-flex m-auto"
          style={{
            minHeight: 56,
            alignItems: "center",
          }}
        >
          <span></span>
          <div>
            {isValidButtonNext && (
              <CButton
                color="success"
                style={{ position: "absolute", right: 3, top: 10 }}
                onClick={() => actionNextStep()}
              >
                <span>Proximo</span>
                <FaArrowRight className="ml-2" size={20} />
              </CButton>
            )}

            {isValidButtonBack && (
              <CButton
                color="secondary"
                style={{ position: "absolute", left: 3, top: 10 }}
                onClick={() => actionPreviousStep()}
              >
                <FaArrowLeft size={20} className="mr-2" />
                <span>Voltar</span>
              </CButton>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TheFooterNextStepExterno;
