import React, { useEffect, useState } from "react";
import {
  CCard,
  CCardHeader,
  CCardTitle,
  CCardBody,
  CRow,
  CSelect,
  CLabel,
  CCardFooter,
  CButton,
  CInput,
} from "@coreui/react";
import ListProducts from "../../views/subdomain/ListProducts";
import { createProdutoFrete } from "../../utils/subdomain";
import { getPedidoExterno } from "../../controller/RequestSalesController";
import {
  setDangerAlertNotifications,
  setSuccessAlertNotifications,
} from "../../controller/AlertNotificationsController";
import { useDispatch } from "react-redux";
import AlertNotifications from "../AlertNotifications";
import StatusPedidoBadge from "../Badges/StatusPedido";

function StatusPedidoCard({ error, restaurante, pedido, monotoken }) {
  const [pedidoState, setPedidoState] = useState(pedido);
  
  let { cliente } = pedidoState;
  let nome = cliente.nome;
  let metodoPagamento = pedido.metodo_pagamento;
  let produtos = [
    ...pedidoState.produtos,
    createProdutoFrete(cliente.rua, cliente.bairro, pedidoState.frete),
  ];

  let dispatch = useDispatch();
  let statusPedido = pedidoState.status_pedido;

  const handleGetPedido = async (pedidoState) => {
    let pedidoId = pedidoState.id;

    let token = monotoken.token;

    let response = await getPedidoExterno({
      pedidoId: pedidoId,
      monotoken: token,
    });

    let pedidoResponse = response.data;

    if (pedidoResponse.error) {
      setDangerAlertNotifications(dispatch, {
        message: `Não foi possível atualizar o Status do Pedido\n${pedidoResponse.error.message}`,
      });
      return;
    }

    let oldStatusPedido = pedidoState.status_pedido;
    let newStatusPedido = pedidoResponse.status_pedido;

    console.log(oldStatusPedido, newStatusPedido);
   
    setPedidoState(pedidoResponse);
  };

  const getPedido = () => {
    return pedidoState ? pedidoState : pedido;
  };

  const handleUpdatePedido = (pedidoState) => {
    setInterval(async () => {
      console.log('setInterval', pedidoState)
      await handleGetPedido(pedidoState);
    }, 10000);
  };

  useEffect(async () => {
    // console.log("");

    handleUpdatePedido(pedidoState);

    return () => {};
  }, []);

  let idPedido = pedido.id;
  return (
    <div>
      <CCard>
        <CCardHeader>
          <CCardTitle>
            Pedido - #{idPedido} - {nome}
            <StatusPedidoBadge statusPedido={statusPedido} realTime />
          </CCardTitle>
        </CCardHeader>
        <CCardBody>
          <div>
            <b>{statusPedido.descricao}</b> <br />
            <b>O pagamento será realizado via {metodoPagamento.titulo}</b>
            <br />
          </div>
          <hr />
          <ListProducts produtos={produtos} />
          <div></div>
        </CCardBody>
      </CCard>

      <AlertNotifications />
    </div>
  );
}

export default StatusPedidoCard;
