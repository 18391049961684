import * as Types from '../redux/ActionsTypes';
import { COLOR } from '../utils/color';

export function setSuccessAlertNotifications(
  dispatch,
  { message, time = 5000 }
) {
  if (!dispatch) {
    throw new Error('Parâmetro "dispatch" é obrigatório');
  }

  const alertNotifications = {
    color: COLOR.success,
    message,
    time,
    icon: 'cil-check',
  };

  dispatch({ type: Types.CLEAR_ALERT_NOTIFICATIONS });
  dispatch({ type: Types.SET_ALERT_NOTIFICATIONS, alertNotifications });
}
export function setDangerAlertNotifications(
  dispatch,
  { message, time = 5000 }
) {
  if (!dispatch) {
    throw new Error('Parâmetro "dispatch" é obrigatório');
  }

  const alertNotifications = {
    color: COLOR.danger,
    message,
    time,
    icon: 'cil-warning',
  };

  dispatch({ type: Types.CLEAR_ALERT_NOTIFICATIONS });
  dispatch({ type: Types.SET_ALERT_NOTIFICATIONS, alertNotifications });
}
