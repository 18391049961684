export const SET_TOKEN_USER = 'SET_TOKEN_USER';
export const SET_USUARIO_INFO = 'SET_USUARIO_INFO';
export const SET_RESTAURANTE_INFO = 'SET_RESTAURANTE_INFO';
export const SET_DEVICES = 'SET_DEVICES';
export const SET_PRODUCTS = 'SET_PRODUCTS';
export const SET_SALES = 'SET_SALES';
export const SET_OPERATORS = 'SET_OPERATORS';
export const SET_ALERT_NOTIFICATIONS = 'SET_ALERT_NOTIFICATIONS';
export const CLEAR_ALERT_NOTIFICATIONS = 'CLEAR_ALERT_NOTIFICATIONS';
export const SET_RESTAURANT_TABLES = 'SET_RESTAURANT_TABLES';
export const SET_WIDGET_LAST_SALES = 'SET_WIDGET_LAST_SALES';
export const SET_WIDGET_LAST_INCOME = 'SET_WIDGET_LAST_INCOME';
export const SET_PRODUCT_TAGS = 'SET_PRODUCT_TAGS';
export const SET_SUBDOMAIN_PEDIDO_FORM_CONFIRMACAO_PEDIDO = 'SET_SUBDOMAIN_PEDIDO_FORM_CONFIRMACAO_PEDIDO'
export const CLEAR_SUBDOMAIN_PEDIDO_FORM_CONFIRMACAO_PEDIDO = 'CLEAR_SUBDOMAIN_PEDIDO_FORM_CONFIRMACAO_PEDIDO'

export const LOGOUT = 'LOGOUT';

