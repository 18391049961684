import { applyMiddleware, createStore } from "redux";
import logger from "redux-logger";
import * as Types from "./ActionsTypes";

const initialState = {
  sidebarShow: "responsive",
  userInfo: null,
  token: null,
  restauranteInfo: null,
  devices: [],
  products: [],
  sales: [],
  operators: [],
  alertNotifications: null,
  restaurantTables: [],
  widgetLastSales: { dias: {}, total: 0 },
  widgetLastIncome: { dias: {}, total: 0 },
  productTags: [],
  subdomainConfirmacaoPedidoForm: {
    cliente: null,
    endereco: null,
    metodoPagamento: null,
  },
};

function clearAlertNotifications() {
  return { alertNotifications: null };
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case "set":
      return { ...state, ...rest };
    case Types.SET_USUARIO_INFO:
      return { ...state, ...rest };
    case Types.SET_RESTAURANTE_INFO:
      return { ...state, ...rest };
    case Types.SET_TOKEN_USER:
      return { ...state, ...rest };
    case Types.SET_DEVICES:
      return { ...state, ...rest };
    case Types.SET_PRODUCTS:
      return { ...state, ...rest };
    case Types.SET_SALES:
      return { ...state, ...rest };
    case Types.SET_OPERATORS:
      return { ...state, ...rest };
    case Types.SET_ALERT_NOTIFICATIONS:
      return { ...state, ...rest };
    case Types.CLEAR_ALERT_NOTIFICATIONS:
      return { ...state, ...clearAlertNotifications() };
    case Types.SET_RESTAURANT_TABLES:
      return { ...state, ...rest };
    case Types.SET_WIDGET_LAST_SALES:
      return { ...state, ...rest };
    case Types.SET_WIDGET_LAST_INCOME:
      return { ...state, ...rest };
    case Types.SET_PRODUCT_TAGS:
      return { ...state, ...rest };
    case Types.SET_SUBDOMAIN_PEDIDO_FORM_CONFIRMACAO_PEDIDO:
      return { ...state, ...rest };
    case Types.CLEAR_SUBDOMAIN_PEDIDO_FORM_CONFIRMACAO_PEDIDO:
      return { ...state, ...initialState.subdomainConfirmacaoPedidoForm };
    case Types.LOGOUT:
      return initialState;
    default:
      return state;
  }
};

const store = createStore(changeState, applyMiddleware(logger));
export default store;
