export default class StepsSubdomain {
  static TELA_CONFIRMACAO_PEDIDO = "telaConfirmarProduto";
  static TELA_LISTAGEM_PEDIDO = "telaListaProduto";
  static TELA_PEDIDO_CONFIRMADO = "telaPedidoConfirmado";

  static PEDIDO_CONFIRMADO_COM_SUCESSO = "PEDIDO_CONFIRMADO_COM_SUCESSO";
  static PEDIDO_NAO_CONFIRMADO = "PEDIDO_NAO_CONFIRMADO";
  static PEDIDO_CONFIRMACAO_LOADING = "PEDIDO_CONFIRMACAO_LOADING";

  static nextStep(setState, stepAtual) {
    if (stepAtual == this.TELA_LISTAGEM_PEDIDO) {
      setState({
        telaListaProduto: false,
        telaConfirmarProduto: true,
        telaPedidoConfirmado: false,
      });
    } else if (stepAtual == this.TELA_CONFIRMACAO_PEDIDO) {
      setState({
        telaListaProduto: false,
        telaConfirmarProduto: false,
        telaPedidoConfirmado: true,
      });
    } else if (stepAtual == this.TELA_PEDIDO_CONFIRMADO) {
      setState({
        telaListaProduto: true,
        telaConfirmarProduto: false,
        telaPedidoConfirmado: false,
      });
    }
  }
  static previousStep(setState, stepAtual) {
    if (stepAtual == this.TELA_LISTAGEM_PEDIDO) {
      setState({
        telaListaProduto: false,
        telaConfirmarProduto: false,
        telaPedidoConfirmado: true,
      });
    } else if (stepAtual == this.TELA_CONFIRMACAO_PEDIDO) {
      setState({
        telaListaProduto: true,
        telaConfirmarProduto: false,
        telaPedidoConfirmado: false,
      });
    } else if (stepAtual == this.TELA_PEDIDO_CONFIRMADO) {
      setState({
        telaListaProduto: false,
        telaConfirmarProduto: true,
        telaPedidoConfirmado: false,
      });
    }
  }
  static whatIsStep(state) {
    for (const iterator of Object.keys(state)) {
      if (state[iterator]) {
        return iterator;
      }
    }
  }
  static stateWithStep = {
    telaListaProduto: true,
    telaConfirmarProduto: false,
    telaPedidoConfirmado: false,
  };
}
