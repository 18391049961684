import { CBadge } from "@coreui/react";
import React from "react";

function PrecoBadge({preco, style={}, color='success'}) {
  return (
    <>
      <CBadge style={style} color={color}>R$ {preco}</CBadge>{" "}
    </>
  );
}

export default PrecoBadge;
