import Cookies from "js-cookie";
import React from "react";
import { addProductCarrinho, removeProductCarrinho } from "../../../controller/CarrinhoController";
import StepsSubdomain from "../../../controller/StepController";
import ProductScreen from "../ProductScreen";
import TelaConfirmarPedido from "./TelaConfirmarPedido";
import TelaPedidoConfirmado from "./TelaPedidoConfirmado";

function MainScreenStep({ restaurante, produtos, carrinhoProdutos, step }) {
  let renderStep = <div></div>;

  if (step == StepsSubdomain.TELA_LISTAGEM_PEDIDO) {
    renderStep = (
      <ProductScreen
        restaurante={restaurante}
        produtos={produtos}
        actionAddProductScreen={addProductCarrinho}
      />
    );
  } else if (step == StepsSubdomain.TELA_CONFIRMACAO_PEDIDO) {
    renderStep = (
      <TelaConfirmarPedido
        restaurante={restaurante}
        carrinhoProdutos={carrinhoProdutos}
        actionRemoveProductCar={removeProductCarrinho}
      />
    );
  } else if (step == StepsSubdomain.TELA_PEDIDO_CONFIRMADO) {
    renderStep = (
      <TelaPedidoConfirmado
        restaurante={restaurante}
        produtos={carrinhoProdutos}
      />
    )
  }

  return <div>{renderStep}</div>;
}

export default MainScreenStep;
