import React, { useState } from "react";

import {
  CCard,
  CCardHeader,
  CCardBody,
  CCardTitle,
  CCardText,
  CCardImg,
  CCardGroup,
  CCardSubtitle,
  CCardFooter,
  CButton,
  CProgress,
  CBadge,
} from "@coreui/react";

import CIcon from "@coreui/icons-react";
import {BiCart} from "react-icons/bi";
import PrecoBadge from "../../Badges/Preco";

function Item({
  id,
  nome,
  preco,
  imgSrc,
  descricao,
  tags,
  actionDelete,
  actionEdit,
  actionAddCarrinho,
  qtdEstoqueDisponivel,
  quantidadeInicialTotal,
  qtdVendido,
  externo = false,
}) {
  const tagsIds = [];
  tags.map((tag) => tagsIds.push(tag.id));

  const product = {
    id,
    nome,
    preco,
    quantidade: quantidadeInicialTotal,
    imgSrc,
    descricao,
    tags: tagsIds,
  };

  const item = {
    product: true,
  };

  const handleClickDelete = () => {
    actionDelete({ visible: true, product });
  };

  const handleClickEdit = () => {
    actionEdit({ visible: true, product });
  };

  const handleClickAddCarrinho = () => {
    actionAddCarrinho({ visible: true, product });
  };

  const renderFooterInterno = () => (
    <div>
      <CCardGroup>
        <CCardText>Quantidade inicial do estoque</CCardText>
        <CCardText className="ml-auto">{quantidadeInicialTotal}</CCardText>
      </CCardGroup>
      <CCardGroup>
        <CCardText>Quantidade de estoque disponível</CCardText>
        <CCardText className="ml-auto">
          {qtdEstoqueDisponivel - qtdVendido}
        </CCardText>
      </CCardGroup>
      <CCardGroup>
        <CCardText>Quantidade de estoque vendido</CCardText>
        <CCardText className="ml-auto">{qtdVendido}</CCardText>
      </CCardGroup>
      {qtdEstoqueDisponivel - qtdVendido > 0 ? (
        <div>
          <label>% de estoque disponível</label>
          <CProgress
            color={
              ((qtdEstoqueDisponivel - qtdVendido) / quantidadeInicialTotal) *
                100 <
              25
                ? "danger"
                : "success"
            }
            value={
              Math.floor(
                ((qtdEstoqueDisponivel - qtdVendido) / quantidadeInicialTotal) *
                  100
              ) || "100"
            }
            showValue={true}
            className="mb-1 bg-white"
          />
        </div>
      ) : (
        <CCardText className="ml-auto" style={{ color: "red" }}>
          ESTOQUE ESGOTADO
        </CCardText>
      )}
    </div>
  );

  const renderFooterExterno = () => <div></div>;

  const renderButtonInterno = () => (
    <div>
      <CButton onClick={handleClickEdit}>
        <CIcon name="cil-pencil" />
      </CButton>

      <CButton onClick={handleClickDelete}>
        <CIcon name="cil-delete" />
      </CButton>
    </div>
  );

  const renderButtonExterno = () => (
    <div>
      <CButton onClick={handleClickAddCarrinho}>
        <BiCart size={25} />
      </CButton>
    </div>
  );

  return (
    <CCard>
      <CCardHeader>
        <div align="right">
          {!externo ? renderButtonInterno() : renderButtonExterno()}
        </div>
        <CCardImg
          style={{ objectFit: "cover" }}
          src={imgSrc}
          height={200}
          width={"100%"}
        />
      </CCardHeader>

      <CCardBody>
        <CCardGroup className="d-flex flex-column text-left">
          <CCardTitle>
            <CCardGroup>
              <CCardText>{nome}</CCardText>
              <CCardText className="ml-auto">
                <CBadge color="success">R$ {preco}</CBadge>{" "}
              </CCardText>
            </CCardGroup>
          </CCardTitle>
          <CCardSubtitle>{descricao || "\u00A0"}</CCardSubtitle>
        </CCardGroup>
      </CCardBody>

      <CCardFooter>
        {!externo ? renderFooterInterno() : renderFooterExterno()}
      </CCardFooter>
    </CCard>
  );
}

export default Item;
