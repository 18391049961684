import {
  CAlert,
  CButton,
  CCard,
  CCardBody,
  CCol,
  CForm,
  CFormGroup,
  CInput,
  CInputRadio,
  CLabel,
} from "@coreui/react";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setDangerAlertNotifications } from "../../controller/AlertNotificationsController";
import { updateStateConfirmPedidoController } from "../../controller/SubdomainFormConfirmacaoPedidoController";

function FormCliente({state}) {  
  let inistateNome = ""
  let inistateTelefone = ""
  let iniConfirm = false
  if (state){
    inistateNome = state.nome
    inistateTelefone = state.telefone
    iniConfirm = true
  }

  const [confirm, setConfirm] = useState(iniConfirm);
  const [nome, setNome] = useState(inistateNome);
  const [telefone, setTelefone] = useState(inistateTelefone);

  const dispatch = useDispatch();
  const stateConfirmPedido = useSelector(
    (state) => state.subdomainConfirmacaoPedidoForm
  );

  const confirmCliente = (e) => {
    e.preventDefault();
  
    if (!nome.trim()|| !telefone.trim()){
      setDangerAlertNotifications(dispatch, {"message": "Por favor, confira as informações. Nome e Telefone são obrigatórios."})
      return 
    }

    let newStateConfirm = {
      ...stateConfirmPedido,

      cliente: {
        nome: nome,
        telefone: telefone,
      },
      
    };
    setConfirm(true);
    updateStateConfirmPedidoController(
      dispatch,
      newStateConfirm
    );
  };

  const backCliente = (e)=>{
    setConfirm(false)

    let newStateConfirm = {
      ...stateConfirmPedido,
      cliente: null,
      
    };
    updateStateConfirmPedidoController(
      dispatch,
      newStateConfirm
    );
    
  }

  return (
    <div>
      <div className={`editableScreen ${!confirm ? "" : "d-none"}`}>
        <CForm onSubmit={confirmCliente}>
          <CFormGroup>
            <CLabel>
              <b>Nome</b>
            </CLabel>
            <CInput
              onChange={(e) => setNome(e.target.value)}
              value={nome}
              name="nome"
              required
            ></CInput>
          </CFormGroup>

          <CFormGroup>
            <CLabel>
              <b>Telefone</b>
            </CLabel>
            <CInput
              onChange={(e) => setTelefone(e.target.value)}
              value={telefone}
              name="telefone"
            ></CInput>
          </CFormGroup>
          <CButton type="submit" color="primary">
            Confirmar Informações do Cliente
          </CButton>
        </CForm>
      </div>
      <div className={`confirmScreen ${confirm ? "" : "d-none"}`}>
        <CAlert color="success">
          <div>
            <ul>
              <li>
                <b>Nome</b> - {nome}
              </li>
              <li>
                <b>Telefone</b> - {telefone}
              </li>
            </ul>
          </div>
          <hr />

          <span>Vamos Utilizar Essa Informação Para Realizar a Entrega.</span>
        </CAlert>

        <CButton color="secondary" onClick={backCliente}>
          Voltar a Editar
        </CButton>
      </div>
    </div>
  );
}

export default FormCliente;
