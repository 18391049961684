import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { TheFooter } from "../../containers";
import TheFooterNextStepExterno from "../../containers/TheFooterNextStepExterno";
import TheHeaderExterno from "../../containers/TheHeaderExterno";
import {
  addProductCarrinho,
  removeProductCarrinho,
} from "../../controller/CarrinhoController";
import BrowserIntegrations from "../../utils/browser";
import StepsSubdomain from "../../controller/StepController";
import MainScreenStep from "./ScreenStep/MainScreenStep";
import { CContainer } from "@coreui/react";
import { useDispatch, useSelector } from "react-redux";
import AlertNotifications from "../../components/AlertNotifications";

const MainSubdomain = ({ restaurante, produtos }) => {
  const [state, setState] = useState(StepsSubdomain.stateWithStep);

  let whatIsStep = () => StepsSubdomain.whatIsStep(state);
  let nextStep = (step) => StepsSubdomain.nextStep(setState, step);
  let previousStep = (step) => StepsSubdomain.previousStep(setState, step);


  const [carrinhoProdutos, setCarrinhoProdutos] = useState(
    Cookies.getJSON("carrinhoProdutos") || []
  );

  useEffect(() => {
    BrowserIntegrations.listenCookieChange(() => {
      let cookies = Cookies.getJSON("carrinhoProdutos");
      setCarrinhoProdutos(cookies);
    });
    return () => {};
  }, []);

  return (
    <div>
      <div className="c-app c-default-layout">
        <div className="c-wrapper">
          <TheHeaderExterno
            nomeRestaurante={restaurante.nome}
            actionAddProductScreen={addProductCarrinho}
            actionRemoveProductCar={removeProductCarrinho}
            carrinhoProdutos={carrinhoProdutos}
          />
          <div className="c-body">
            <CContainer>
              <MainScreenStep
                produtos={produtos}
                restaurante={restaurante}
                step={whatIsStep()}
                carrinhoProdutos={carrinhoProdutos}
              />
            </CContainer>
            <TheFooterNextStepExterno
              currentStep={whatIsStep()}
              actionNextStep={() => nextStep(whatIsStep())}
              actionPreviousStep={() => previousStep(whatIsStep())}
              nomeRestaurante={restaurante.nome}
              hasProdutoInCarrinho={carrinhoProdutos.length > 0}
            />
          </div>
          <AlertNotifications/>

          <TheFooter />
        </div>
      </div>
    </div>
  );
};

export default MainSubdomain;
