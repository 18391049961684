import api from "../services/api";

export async function getRestauranteInfoSubdomain({ subdomain }) {
  try {
    const response = await api.get(`/externo/restauranteInfo?subdomain=${subdomain}`);
    return response.data;
  } catch (error) {
    return { error: error };
  }
}


export async function getProducts({ subdomain }) {
    try {
      const response = await api.get(`/externo/produtos?subdomain=${subdomain}`);  
      return response.data;
    } catch (error) {
      return { error: error };
    }
  }