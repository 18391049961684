import Cookies from "js-cookie";
import React from "react";
import { useDispatch } from "react-redux";
import Item from "../../components/Product/Item";
import { setSuccessAlertNotifications } from "../../controller/AlertNotificationsController";

function ProductScreen({ produtos, restaurante, actionAddProductScreen }) {
  const dispatch = useDispatch();

  const handleActionAddCarrinho = (item) => {
    let nome = item.product.nome
    setSuccessAlertNotifications(dispatch, {
      message: `${nome} foi adicionado ao carrinho`,
    });
    return actionAddProductScreen(item);
  };

  return (
    <div>
      <div className="container">
        <div className="row d-flex justify-content-center justify-content-md-start">
          {produtos.map((produto) => {
            return (
              <div className="col-10 col-md-4" key={produto.id}>
                <Item
                  id={produto.id}
                  nome={produto.titulo}
                  preco={produto.preco}
                  imgSrc={produto.src_img}
                  tags={[]}
                  actionAddCarrinho={handleActionAddCarrinho}
                  descricao={produto.descricao}
                  externo
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default ProductScreen;
