import React from 'react';

const Dashboard = React.lazy(() => import('./views/inicio/Dashboard'));

const Sales = React.lazy(() => import('./views/monolitoOptions/sales/Sales'));
const RequestSales = React.lazy(() => import('./views/monolitoOptions/requestSales/RequestSales'));
const Operators = React.lazy(() => import('./views/monolitoOptions/operators/Operators'));
const Devices = React.lazy(() => import('./views/monolitoOptions/devices/Devices'));
const Settings = React.lazy(() => import('./views/monolitoOptions/settings/Settings'));
const Product = React.lazy(() => import('./views/monolitoOptions/product/Product'));
const RestaurantTables = React.lazy(() => import('./views/monolitoOptions/restaurantTables/RestaurantTables'));
const RestaurantTableInfo = React.lazy(() => import('./views/monolitoOptions/restaurantTableInfo/RestaurantTableInfo')) ;



const routes = [
    { path: '/', exact: true, name: 'Home' },
    { path: '/dashboard', name: 'Dashboard', component: Dashboard },
    { path: '/vendas/', exact: true, name: 'Vendas', component: Sales },
    { path: '/pedidos/', exact: true, name: 'Pedidos', component: RequestSales},
    { path: '/produtos/', exact: true, name: 'Produtos', component: Product },
    { path: '/responsavel-atendimento/', exact: true, name: 'Responsável Pelo Atendimento', component: Operators },
    { path: '/dispositivos-conectados/', exact: true, name: 'Dispositivos Conectados', component: Devices },
    { path: '/mesas/', exact: true, name: 'Mesas do Restaurante', component: RestaurantTables },
    { path: '/mesas/:idMesa', exact: true, name: 'Mesa', component: RestaurantTableInfo },
    { path: '/configuracoes/', exact: true, name: 'Configurações', component: Settings },
];

export default routes;
