class BrowserIntegrations {
  static baseUrl = "http://localhost:8000";
  static urls = {
    404: `${BrowserIntegrations.baseUrl}/404`,
  };

  static getSubdomain = function getSubdomain(window) {
    let hostname = window.location.hostname;
    var regexParse = new RegExp("[a-z-0-9]{2,63}.[a-z.]{2,5}$");
    var urlParts = regexParse.exec(hostname);
    return hostname.replace(urlParts[0], "").slice(0, -1);
  };

  static redirectUrl = function redirectUrl(url) {
    let path = "";
    if (BrowserIntegrations.urls.hasOwnProperty(url)) {
      path = BrowserIntegrations.urls[url];
    }
    return (window.location.href = path);
  };

  static getParameterByName = function (name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  static listenCookieChange = function listenCookieChange(
    callback,
    interval = 500
  ) {
    let lastCookie = document.cookie;
    setInterval(() => {
      let cookie = document.cookie;
      if (cookie !== lastCookie) {
        try {
          callback({ oldValue: lastCookie, newValue: cookie });
        } finally {
          lastCookie = cookie;
        }
      }
    }, interval);
  };

  static getUrlComplete = () => window.location.href;
}

export default BrowserIntegrations;
