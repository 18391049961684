import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../../components/Loading";
import { clearCookiesCarrinhosProdutos, setCookiesMonotokenPedido, setCookiesPedido } from "../../../controller/CarrinhoController";
import StepsSubdomain from "../../../controller/StepController";
import {
  createCliente,
  createPedido,
} from "../../../controller/SubdomainFormConfirmacaoPedidoController";
import StatusPedidoCard from "../../../components/Pedido/StatusPedidoCard";
import { Redirect, useHistory } from "react-router";
import { setDangerAlertNotifications, setSuccessAlertNotifications } from "../../../controller/AlertNotificationsController";

function TelaPedidoConfirmado({ restaurante, produtos }) {
  const stateConfirmPedidoForm = useSelector(
    (state) => state.subdomainConfirmacaoPedidoForm
  );

  const [pedido, setPedido] = useState({});
  const [monotoken, setMonotoken] = useState({});
  const dispatch = useDispatch();
  const history = useHistory()
  const [statusPagePedido, setStatusPagePedido] = useState(
    StepsSubdomain.PEDIDO_CONFIRMACAO_LOADING
  );

  const { cliente, endereco, metodoPagamento } = stateConfirmPedidoForm;

  const handleCreateCliente = async (cliente, endereco) => {
    let clienteResponse = await createCliente(cliente, endereco);
    if (clienteResponse.error) {
      setDangerAlertNotifications(dispatch, {'message': "Ocorreu um erro ao criar o Cliente. Operação encerrada. Confira os dados e tente novamente"});
      history.push("/");
      throw new Error("Não foi possível registrar o Cliente no Sistema");
      
    }
    setSuccessAlertNotifications(dispatch, {'message': "Cliente Criado Com Sucesso"})
    return clienteResponse.data.id;
  };

  const handleCreatePedidos = async (idCliente) => {
    let subdomain = restaurante.subdomain; 
    let idMetodoPagamento = metodoPagamento.id;
    let idsProdutos = produtos.map((el) => el.id);
    let dataPedido = null;
    let enderecoid = endereco.bairro.id;

    let pedidoResponse = await createPedido(
      subdomain,
      idCliente,
      idMetodoPagamento,
      idsProdutos,
      enderecoid,
    );
    if (pedidoResponse.error) {
      setDangerAlertNotifications(dispatch, {'message': "Ocorreu um erro ao criar o Cliente. Operação encerrada. Confira os dados e tente novamente"});
      history.push("/")
      throw new Error("Não foi possível registrar Gerar o Pedido");
    }
    let monotoken = pedidoResponse.data;
    dataPedido  = pedidoResponse.data.pedido;

    handleCookiesPedido(dataPedido);
    setCookiesMonotokenPedido(monotoken)
    setMonotoken(monotoken)


    return dataPedido;
  };

  const handleCookiesPedido = (pedido) => {
    clearCookiesCarrinhosProdutos()
    console.log(pedido)
    setCookiesPedido(pedido)
  };

  const handleRequestCreatePedido = async () => {
    try {
      let idCliente = await handleCreateCliente(cliente, endereco);
      let pedido = await handleCreatePedidos(idCliente);
      console.log(pedido)
      if (pedido) {
        setPedido(pedido);
        setStatusPagePedido(StepsSubdomain.PEDIDO_CONFIRMADO_COM_SUCESSO);
      }
    } catch {
      setStatusPagePedido(StepsSubdomain.PEDIDO_NAO_CONFIRMADO);
    }
  };

  useEffect(async () => {
    await handleRequestCreatePedido();

    return () => {};
  }, []);

  let response = <div></div>;
  if (statusPagePedido === StepsSubdomain.PEDIDO_CONFIRMACAO_LOADING) {
    response = <Loading visible />;
  } else if (
    statusPagePedido === StepsSubdomain.PEDIDO_CONFIRMADO_COM_SUCESSO
  ) {
    let stateToRedirect = btoa(JSON.stringify(monotoken))
    response = <Redirect to={`/p?i=${stateToRedirect}`}  />;
  } else if (statusPagePedido === StepsSubdomain.PEDIDO_NAO_CONFIRMADO) {
    response = <StatusPedidoCard restaurante= {restaurante} error={{}} />;
  }
  return <div>{response}</div>;
}

export default TelaPedidoConfirmado;
