import { CBadge, CSpinner } from "@coreui/react";
import React from "react";

function BadgeRealTimeComponent({
  style = { fontSize: 17 },
  className = "mb-2 mt-2 ml-2 mr-2",
}) {
  return (
    <CBadge color="success" className={className} style={style}>
      <span>Atualizando em tempo real </span>
      <CSpinner color="warning" size="sm" />
    </CBadge>
  );
}

export default BadgeRealTimeComponent;
