import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, useHistory } from "react-router-dom";
import AlertNotifications from "../../components/AlertNotifications";
import Loading from "../../components/Loading";
import {
  getProducts,
  getRestauranteInfoSubdomain,
} from "../../controller/SubdomainMainController";
import BrowserIntegrations from "../../utils/browser";
import Page404 from "../pages/page404/Page404";
import MainSubdomain from "./MainSubdomain";
import PedidoConfirmadoSubdomain from "./PedidoConfirmadoSubdomain";

const SubdomainRouterExterno = ({ subdomain, redirect }) => {
  let dispatch = useDispatch();
  const [externoInfo, setExternoInfo] = useState(null);
  let history = useHistory();

  async function getRestauranteInfo() {
    let restaurante = await getRestauranteInfoSubdomain({ subdomain });

    if (redirect){
      history.push(redirect);
    }
    
    if (restaurante.error) {
      history.push("404");
    } else {
      let produtos = await getProducts({ subdomain });

      let dataExterno = {
        restaurante: restaurante.data,
        produtos: produtos.data,
      };

      return setExternoInfo(dataExterno);
    }
  }

  useEffect(() => {
    if (!externoInfo) {
      getRestauranteInfo();
    }
  }, []);

  return (
    <>
      <Route
        exact
        path="/404"
        name=""
        render={(props) => <Page404 text={"Restaurante não encontrado."} />}
      />
      {externoInfo ? (
        <div>

          <Route
            exact
            path="/"
            name={externoInfo.restaurante.nome}
            render={(props) => (
              <MainSubdomain
                restaurante={externoInfo.restaurante}
                produtos={externoInfo.produtos}
              />
            )}
          />

          <Route
            exact
            path="/p"
            render={(props) => <PedidoConfirmadoSubdomain />}
          />
          
        </div>
      ) : (
        <div>
          <Loading visible />
        </div>
      )}
    </>
  );
};

export default SubdomainRouterExterno;
