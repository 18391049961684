import api from '../services/api';

export async function getToken(token) {
  try {
    const response = await api.post('/tokenMobile/store', null, {
      headers: { Authorization: 'Bearer ' + token },
    });
    return response.data.data;
  } catch (error) {
    return null;
  }
}

export async function verifyToken(token, monotoken) {
  try {
    const response = await api.get(
      `/tokenMobile/isValid?monotoken=${monotoken}`,
      {
        headers: { Authorization: 'Bearer ' + token },
      }
    );
    return response.data.data.valid;
  } catch (error) {
    return false;
  }
}

export async function deleteDevice({ id, token }) {
  try {
    const response = await api.delete(`/dispositivos/${id}`, {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    });

    const dataToReturn = { response, error: null };

    return dataToReturn;
  } catch (error) {
    const dataToReturn = { response: null, error: error };
    return dataToReturn;
  }
}

export async function updateSetGarconIdDevice({ idDevice, token, idGarcon }) {
  try {
    const response = await api.put(
      `/dispositivos/${idDevice}`,
      {
        garcon_id: idGarcon,
      },
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      }
    );

    const dataToReturn = { response, error: null };

    return dataToReturn;
  } catch (error) {
    const dataToReturn = { response: null, error: error };
    return dataToReturn;
  }
}

export async function getDevices({ token }) {
  const configAxiosRequest = {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  };
  const response = await api.get('dispositivos', configAxiosRequest);

  return response.data;
}
