import {
  CAlert,
  CButton,
  CCard,
  CCardBody,
  CCol,
  CForm,
  CFormGroup,
  CInput,
  CInputRadio,
  CLabel,
} from "@coreui/react";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateStateConfirmPedidoController } from "../../controller/SubdomainFormConfirmacaoPedidoController";
import { setDangerAlertNotifications } from "../../controller/AlertNotificationsController";

function FormEndereco({ enderecosAtendidos, state }) {
  let iniConfirm = false;
  let iniBairro = "";
  let iniFrete = "";
  let iniBairroSelected = "";
  let iniEndereco = "";
  let iniReferencia = "";

  if (state) {
    iniConfirm = true;
    iniBairro = state.bairro.id;

    iniFrete = state.frete;
    iniBairroSelected = state.bairro.bairro;
    iniEndereco = state.endereco;
    iniReferencia = state.referencia;
  }

  const [confirm, setConfirm] = useState(iniConfirm);
  const [bairroState, setBairro] = useState(iniBairro);
  const [frete, setFrete] = useState(iniFrete);
  const [bairroSelected, setBairroSelected] = useState(iniBairroSelected);
  const [endereco, setEndereco] = useState(iniEndereco);
  const [referencia, setReferencia] = useState(iniReferencia);

  const dispatch = useDispatch();
  const stateConfirmPedido = useSelector(
    (state) => state.subdomainConfirmacaoPedidoForm
  );


  const confirmEndereco = (e) => {
    e.preventDefault();
    let bairroObj = enderecosAtendidos.find((el) => el.id === bairroState);

    if (!bairroObj || !endereco.trim()) {
      setDangerAlertNotifications(dispatch, {"message": "Por favor, confira as informações. Bairro e Endereço são dados obrigatórios."});
      return;
    }

    setConfirm(true);
    setBairroSelected(bairroObj.bairro.toString());
    setFrete(Number(bairroObj.preco_entrega));

    let newStateConfirm = {
      ...stateConfirmPedido,
      endereco: {
        endereco: endereco,
        bairro: bairroObj,
        referencia: referencia,
        frete: Number(bairroObj.preco_entrega),
      },
    };

    updateStateConfirmPedidoController(dispatch, newStateConfirm);
  };
  const backEndereco = (e) => {
    setConfirm(false);

    let newStateConfirm = {
      ...stateConfirmPedido,
      endereco: null,
    };

    updateStateConfirmPedidoController(dispatch, newStateConfirm);
  };

  return (
    <div>
      <div className={`editableScreen ${!confirm ? "" : "d-none"}`}>
        <CForm onSubmit={confirmEndereco}>
          <CFormGroup>
            <p>
              <b>Bairro Atendidos</b>
            </p>
            {enderecosAtendidos.map(({ id, cidade, bairro }) => {
              return (
                <div key={id} className="ml-4">
                  <CInputRadio
                    onChange={(e) => {
                      setBairro(id);
                    }}
                    required
                    name={"bairro"}
                    checked={bairroState === id}
                    id={id}
                  />
                  <CLabel
                    required
                    htmlFor={id}
                  >{`${cidade} - ${bairro}`}</CLabel>
                </div>
              );
            })}
          </CFormGroup>
          <CFormGroup>
            <CLabel>
              <b>Endereço</b>
            </CLabel>
            <CInput
              onChange={(e) => setEndereco(e.target.value)}
              name="endereco"
              value={endereco}
              required
            ></CInput>
          </CFormGroup>

          <CFormGroup>
            <CLabel>
              <b>Referência</b>
            </CLabel>
            <CInput
              onChange={(e) => setReferencia(e.target.value)}
              name="referencia"
              value={referencia}
            ></CInput>
          </CFormGroup>
          <CButton type="submit" color="primary">
            Confirmar Endereço e Conferir o Valor do Frete
          </CButton>
        </CForm>
      </div>
      <div className={`confirmScreen ${confirm ? "" : "d-none"}`}>
        <CAlert color="success">
          <div>
            <ul>
              <li>
                <b>Bairro</b> - {bairroSelected}
              </li>
              <li>
                <b>Endereço</b> - {endereco} {referencia}
              </li>
              <li>
                <b>Frete</b> - {frete}
              </li>
            </ul>
          </div>
          <hr />

          <span>
            Endereços definido com sucesso - Seu Frete será{" "}
            <b>{`R$ ${frete}`}</b>
          </span>
        </CAlert>

        <CButton color="secondary" onClick={backEndereco}>
          Voltar a Editar
        </CButton>
      </div>
    </div>
  );
}

export default FormEndereco;
