import { CSpinner, CContainer } from "@coreui/react";
import React from "react";

function Loading({ visible = false, size, classNameContainer, color }) {
  if (!size) {
    size = 40;
  }
  if (!classNameContainer) {
    classNameContainer = "d-flex justify-content-center align-items-center";
  }

  if (!color) {
    color = "primary";
  }

  return visible ? (
    <CContainer className={classNameContainer}>
      <CSpinner color={color} style={{ width: size, height: size }} />
    </CContainer>
  ) : (
    <div></div>
  );
}

export default Loading;
