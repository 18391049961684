import Cookies from "js-cookie";
import * as Types from "../redux/ActionsTypes";

class SessaoController {
  static closeSession = (history, dispatch, withRedirectToLogin = false) => {
    Cookies.remove("userInfo");
    Cookies.remove("restauranteInfo");
    Cookies.remove("token");

    dispatch({ type: Types.LOGOUT });

    if (withRedirectToLogin) {
      history.push("/login");
    }
  };
}

export default SessaoController;
