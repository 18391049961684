import { CBadge, CButton, CListGroup, CListGroupItem } from "@coreui/react";
import React from "react";
import { FaEraser, FaTrash, FaTrashAlt } from "react-icons/fa";
import PrecoBadge from "../../components/Badges/Preco";
import { COLOR } from "../../utils/color";
import DataHandler from "../../utils/data";

function ListProducts({
  produtos,
  actionRemoveProductCar,
  removeTotalValue = false,
  trash = false
}) {

  let produtosById = DataHandler.handleDataToViewProductCarrinho(produtos);
  let precoTodosProdutos = produtos
    .reduce((total, p) => (total += Number(p.preco)), 0)
    .toFixed(2);

  return (
    <div>
      <CListGroup>
        {Object.keys(produtosById).map((p, idx) => {
          let produtosArr = produtos.filter((el) => p == el.id);
          let produto = {
            ...produtosArr[0],
            qntdCarrinho: produtosArr.length,
          };

          return (
            <CListGroupItem key={idx}>
              <div className="d-flex w-100 justify-content-between align-items-center mb-1">
                <div className="d-flex">
                  <CBadge
                    style={{ fontSize: "initial" }}
                    color="primary"
                    className="m-0 mr-2"
                  >
                    {produto.qntdCarrinho}
                  </CBadge>
                  <strong className="mr-2">X</strong>
                  <h5 className="mb-1">{produto.nome || produto.titulo}</h5>
                </div>

                <h5 className="d-inline">
                  <PrecoBadge preco={produto.preco} />
                </h5>
              </div>
              <p className="mb-1">{produto.descricao}</p>
              <CButton
                className="p-1 m-0"
                style={{
                  position: "absolute",
                  bottom: "10px",
                  right: "20px",
                }}
                onClick={() => actionRemoveProductCar({ product: produto })}
              >                
                {trash && <FaTrashAlt style={{ color: COLOR.danger }} size={15} /> }
              </CButton>
            </CListGroupItem>
          );
        })}

        {!removeTotalValue && (
          <CListGroupItem active>
            <h4>Valor Total:</h4>
            <h2>
              <PrecoBadge preco={precoTodosProdutos} />
            </h2>
          </CListGroupItem>
        )}
      </CListGroup>
    </div>
  );
}

export default ListProducts;
