import api from '../services/api';

export async function getGarcons({ token }) {
  try {
    const response = await api.get('/garcons', {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    });

    return response.data;
  } catch (error) {
    return { error: error };
  }
}

export async function addGarcom({ token, nome, sobrenome, cpf }) {
  try {
    const response = await api.post(
      '/garcons',
      { nome, sobrenome, cpf },
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      }
    );

    return response.data;
  } catch (error) {
    return { error: error };
  }
}

export async function editGarcom({ token, id, nome, sobrenome, cpf }) {
  try {
    const response = await api.patch(
      `/garcons/${id}`,
      { nome, sobrenome, cpf },
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      }
    );

    return response.data;
  } catch (error) {
    return { error: error };
  }
}

export async function deleteGarcom({ id, token }) {
  try {
    const response = await api.delete(`/garcons/${id}`, {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    });

    const dataToReturn = { response, error: null };

    return dataToReturn;
  } catch (error) {
    const dataToReturn = { response: null, error: error };
    return dataToReturn;
  }
}