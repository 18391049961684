import React, { useEffect, useState } from "react";
import {
  CBadge,
  CButton,
  CHeader,
  CHeaderNav,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
} from "@coreui/react";

import { useHistory } from "react-router";
import { BiCart } from "react-icons/bi";
import Cookies from "js-cookie";
import BrowserIntegrations from "../utils/browser";
import ListProducts from "../views/subdomain/ListProducts";

const TheHeaderExterno = ({ nomeRestaurante, carrinhoProdutos=[], actionRemoveProductCar }) => {
 

  const [visibleModalCarrinho, setVisibleModalCarrinho] = useState(false);

  

  function renderModalCarrinho() {
    return (
      <div id="modalCarrinho">
        <CModal show={visibleModalCarrinho} onClose={() => setVisibleModalCarrinho(false)}>
          <CModalHeader>
            <CModalTitle>Carrinho de Compras</CModalTitle>
          </CModalHeader>
          <CModalBody>
            <ListProducts
              produtos={carrinhoProdutos}
              actionRemoveProductCar={actionRemoveProductCar}
            />
          </CModalBody>
        </CModal>
      </div>
    );
  }

  return (
    <CHeader withSubheader>
      <CHeaderNav className="d-flex m-auto">
        <span>
          <b>{nomeRestaurante}</b>
        </span>
        <div>
          <CButton
            style={{ position: "absolute", right: 3, top: 10 }}
            onClick={() => setVisibleModalCarrinho(!visibleModalCarrinho)}
          >
            <BiCart size={25} />
            <CBadge color="secondary">
              {carrinhoProdutos ? carrinhoProdutos.length : 0}
            </CBadge>
          </CButton>
          {renderModalCarrinho()}
        </div>
      </CHeaderNav>
    </CHeader>
  );
};

export default TheHeaderExterno;
