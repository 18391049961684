import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Types from "../redux/ActionsTypes";

import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { useHistory } from "react-router";

import "../scss/style.scss";
import BrowserIntegrations from "../utils/browser";
import SubdomainRouterExterno from "./subdomain/SubdomainRouterExterno";
import SessaoController from "../controller/SessaoController";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("../containers/TheLayout"));

// Pages
const Login = React.lazy(() => import("../views/pages/login/Login"));
const Register = React.lazy(() => import("../views/pages/register/Register"));
const Page404 = React.lazy(() => import("../views/pages/page404/Page404"));

const handleRedirect = () => {
  let url = BrowserIntegrations.getParameterByName('uri');
  if (url) {
    url = atob(url);
    let urlRedirect = url.replace("/app/", '');
    console.log(urlRedirect)
    return urlRedirect;
  } else {
    return null;
  }
}

const PrivateRoute = ({ component, ...options }) => {
  const isLogged = useSelector((state) => state.token);

  const finalComponent = isLogged ? component : Login;

  return <Route {...options} component={finalComponent} />;
};

function Main() {
  const [subdomain, setsubdomain] = useState("");

  const isSubdomain = function () {
    let homologdev = subdomain === "homolog" || subdomain === "monosoft" || subdomain === "monogarcom" || subdomain === 'www';
    return subdomain !== "" && !homologdev;
  };
  const history = useHistory();
  const dispatch = useDispatch();

  const handleSessionExistente = () => {
    let token = Cookies.getJSON("token");
    let userInfo = Cookies.getJSON("userInfo");
    let restauranteInfo = Cookies.getJSON("restauranteInfo");

    if (token && userInfo && restauranteInfo) {
      dispatch({ type: Types.SET_USUARIO_INFO, userInfo: userInfo });
      dispatch({
        type: Types.SET_RESTAURANTE_INFO,
        restauranteInfo: restauranteInfo,
      });
      dispatch({ type: Types.SET_TOKEN_USER, token: token });
    }
  };

  useEffect(() => {
    let subdomain = BrowserIntegrations.getSubdomain(window);
    subdomain = subdomain.split('.')[0]
    console.log(subdomain)
    if (subdomain) {
      setsubdomain(subdomain);
    }

    handleSessionExistente();
  }, []);







  let redirect = handleRedirect();
  return (
    <BrowserRouter basename="/app">
      <React.Suspense fallback={loading}>
        <Switch>
          {isSubdomain() ? (
            <SubdomainRouterExterno subdomain={subdomain} redirect={redirect} />
          ) : (
            <AdminRouters redirect={redirect} />
          )}
        </Switch>
      </React.Suspense>
    </BrowserRouter>
  );
}

const AdminRouters = ({redirect}) => {
  return (
    <>
      <Route
        path="/login"
        name="Login Monogarcom"
        render={(props) => <Login {...props} />}
      />
      <Route
        path="/register"
        name="Registro Monogarcom"
        render={(props) => <Register {...props} />}
      />
      <Route
        path="/404"
        name="Page 404"
        render={(props) => <Page404 {...props} />}
      />

      <PrivateRoute
        path="/"
        name="Home"
        render={(props) => <TheLayout {...props} redirect={redirect} />}
      />
    </>
  );
};

export default Main;
