import api from "../services/api";

export async function addProduct({
  nome,
  preco,
  quantidade,
  imgSrc,
  descricao,
  tags,
  restauranteId,
  token,
  file
}) {
  try {
    const body = {
      titulo: nome,
      preco,
      quantidade,
      src_img:
      imgSrc,
      descricao,
      tag_produtos_id: tags,
      restaurante_id: restauranteId,
    };

    let bodyRequest = handleUploadFile(file, body);


    const response = await api.post("/produtos", bodyRequest, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    const { data } = response;

    const dataToReturn = { response, error: null, data };

    return dataToReturn;
  } catch (error) {
    console.log(error)
    const dataToReturn = { response: null, error: error, data: null };
    return dataToReturn;
  }
}

export async function editProduct({
  nome,
  preco,
  quantidade,
  imgSrc,
  descricao,
  tags,
  restauranteId,
  id,
  token,
  file,
}) {
  try {
    const body = {
      titulo: nome,
      preco,
      quantidade,
      src_img:
      imgSrc,
      descricao,
      tag_produtos_id: tags,
      restaurante_id: restauranteId,
    };

    let bodyRequest = handleUploadFile(file, body);

    const response = await api.post(`/produtos/${id}`, bodyRequest, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    const { data } = response;

    const dataToReturn = { response, error: null, data };

    return dataToReturn;
  } catch (error) {
    const dataToReturn = { response: null, error: error, data: null };
    return dataToReturn;
  }
}

export async function getProducts({ token }) {
  try {
    const response = await api.get("/produtos", {
      headers: {
        Authorization: "Bearer " + token,
      },
    });

    return response.data;
  } catch (error) {
    return { error: error };
  }
}

export async function deleteProduct({ id, token }) {
  try {
    const response = await api.delete(`/produtos/${id}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });

    const dataToReturn = { response, error: null };

    return dataToReturn;
  } catch (error) {
    const dataToReturn = { response: null, error: error };
    return dataToReturn;
  }
}


function handleUploadFile (file, body){
  let bodyRequest = body;
  if (file){
    body['imagem'] = file;
    let formData = new FormData();        
    for ( let key in body ) {
      formData.append(key, body[key]);
    }
    bodyRequest = formData;
  } 

  return bodyRequest;
}