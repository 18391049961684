import React, { useEffect, useState } from "react";
import { Redirect, useHistory, useLocation } from "react-router";
import BrowserIntegrations from "../../utils/browser";
import StatusPedidoCard from "../../components/Pedido/StatusPedidoCard";

function PedidoConfirmadoSubdomain() {
  const location = useLocation();
  const history = useHistory();
  let search = location.search;
  let restaurante = {};

  const [pedido, setPedido] = useState(null);
  const [monotoken, setMonotoken] = useState(null);

  const handleStateParams = () => {
    let decodedPedido = BrowserIntegrations.getParameterByName("i", search);
    let {pedido, monotoken} = JSON.parse(atob(decodedPedido));
    
    setMonotoken(monotoken);
    setPedido(pedido);
  };

  useEffect(() => {
    try {
      handleStateParams();
    } catch (error) {
      history.push("/404");
      return;
    }

    return () => {};
  }, []);

  console.log(pedido);

  return (
    <div>
      {pedido && <StatusPedidoCard monotoken={monotoken} restaurante={restaurante} pedido={pedido} />}
    </div>
  );
}

export default PedidoConfirmadoSubdomain;
